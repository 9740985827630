import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

if (process.env.NODE_ENV === "development") {
  /* eslint-disable-next-line no-restricted-globals */
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const firebaseConfig = {
  apiKey: "AIzaSyA9BU6ZJKBwLhmMsh4kzFKIAaNFU97DfkU",
  authDomain: "ref-home.firebaseapp.com",
  databaseURL: "https://ref-home.firebaseio.com",
  projectId: "ref-home",
  storageBucket: "ref-home.appspot.com",
  messagingSenderId: "942178101399",
  appId: "1:942178101399:web:4fb839b237d65052ca61d2",
  measurementId: "G-P68777FFZ9",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize Firebase App Check
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcR-BEqAAAAABdTk-IYzI3pWYTojjVH8vfVyIUv"),
  isTokenAutoRefreshEnabled: true, // Enable automatic token refresh,
});

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

export { db, appCheck, analytics };
