import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          documentTitle: "Ref Software and Tech",
          companyName: "ref",
          commpanyNameSuffix: "software and tech",
          main: "Mindfully created innovative apps and software. #c='text-slate-500 dark:text-zinc-400'#We are a new age company where tech is designed thoughtfully, coded carefully and used instinctively.#c#",
          "app-tezene-title": "Tezene",
          "app-tezene-description":
            "Turkish folk music instrument tutor in pocket.",
          "app-turkish-title": "Turkish",
          "app-turkish-description": "Ultimate guide for Turkish language.",
          appsTitle: "apps",
          contact: "contact",
          contactTitle: "Let's talk",
          contactDescription: "You can directly contact us via the form below.",
          contactFormName: "Full Name",
          contactFormPhone: "Phone",
          contactFormEmail: "E-mail",
          contactFormMessage: "Message",
          contactFormSubmit: "Send",
          contactFormSuccess: "Your message has been delivered successfully.",
          contactFormSuccessDetail: "We will get back to you very soon.",
          contactFormError:
            "An error occurred while sending the message, please try again.",
          close: "Close",
          imprint: "Imprint",
        },
      },
      fr: {
        translation: {
          documentTitle: "Ref Logiciels et Technologies",
          companyName: "ref",
          commpanyNameSuffix: "logiciels et technologies",
          main: "Des applications et des logiciels innovants créés avec soin. #c='text-slate-500 dark:text-zinc-400'#Nous sommes une entreprise du nouvel âge où la technologie est conçue de manière réfléchie, codée avec soin et utilisée de manière instinctive.#c#",
          "app-tezene-title": "Tezene",
          "app-tezene-description":
            "Tuteur d'instrument de musique folklorique turc dans la poche.",
          "app-turkish-title": "Turkish",
          "app-turkish-description": "Guide ultime de la langue turque.",
          appsTitle: "applications",
          contact: "contactez",
          contactTitle: "Envoyez un message",
          contactDescription:
            "Vous pouvez nous contacter directement via le formulaire ci-dessous.",
          contactFormName: "Nom complet",
          contactFormPhone: "Téléphone",
          contactFormEmail: "E-mail",
          contactFormMessage: "Message",
          contactFormSubmit: "Envoyer",
          contactFormSuccess: "Votre message a été envoyé avec succès.",
          contactFormSuccessDetail: "Nous vous répondrons très bientôt.",
          contactFormError:
            "Une erreur s'est produite lors de l'envoi du message, veuillez réessayer.",
          close: "Fermer",
          imprint: "Mentions légales",
        },
      },
      tr: {
        translation: {
          documentTitle: "Ref Yazılım ve Bilişim",
          companyName: "ref",
          commpanyNameSuffix: "yazılım ve bilişim",
          main: "Özenle yaratılmış uygulamalar ve yazılımlar. #c='text-slate-500 dark:text-zinc-400'#Teknolojinin incelikle tasarlandığı, özenle kodlandığı ve sezgisel kullanıldığı yeni nesil bir şirketiz.#c#",
          "app-tezene-title": "Tezene",
          "app-tezene-description":
            "Günümüz dijital dünyasına uyum sağlamış bağlama öğretmeni.",
          "app-turkish-title": "Türkçe",
          "app-turkish-description": "En kapsamlı nihai Türkçe rehberi.",
          appsTitle: "uygulamalar",
          contact: "iletişim",
          contactTitle: "Mesaj gönderin",
          contactDescription:
            "Aşağıdaki form aracılığıyla bizimle doğrudan iletişime geçebilirsiniz.",
          contactFormName: "Ad Soyad",
          contactFormPhone: "Telefon",
          contactFormEmail: "E-posta",
          contactFormMessage: "Mesaj",
          contactFormSubmit: "Gönder",
          contactFormSuccess: "Mesajınız başarıyla iletildi.",
          contactFormSuccessDetail:
            "En kısa süre içerisinde geri dönüş yapacağız.",
          contactFormError:
            "Mesaj gönderilirken bir hata oluştu, lütfen tekrar deneyin.",
          close: "Kapat",
          imprint: "Künye",
        },
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie"],
    },
  });

export default i18n;
