import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";
import Contact from "./Contact";

const appList = {
  tezene: "1495218175",
  turkish: "6459059099",
};

const urlForApp = (app_id) => {
  if (!app_id) return "";
  if (app_id === "1495218175") return "https://tezene.app";
  return `https://apps.apple.com/app/id${app_id}`;
};

function App() {
  const { t } = useTranslation();
  const [mainText, setMainText] = useState("");
  const [apps, setApps] = useState([]);
  const [router, setRouter] = useState("home"); // home, contact
  const [hasAnimated, setHasAnimated] = useState(false);
  const [contactHasSuccess, setContactHasSuccess] = useState(false);

  useEffect(() => {
    document.title = t("documentTitle");

    const main = t("main");
    const mainText = main.replace(
      /#c='(.*?)'#(.*?)#c#/g,
      "<span class='$1'>$2</span>"
    );
    setMainText(mainText);

    const apps = Object.keys(appList).map((key) => {
      return {
        key: key,
        app_id: appList[key],
        title: t(`app-${key}-title`),
        description: t(`app-${key}-description`),
        icon: `${key}-icon.png`,
        hero: `${key}-hero.png`,
      };
    });
    setApps(apps);
  }, [t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router]);

  if (router === "contact") {
    return (
      <Contact
        t={t}
        hasSuccess={contactHasSuccess}
        onClose={(hasSuccess) => {
          setHasAnimated(true);
          setContactHasSuccess(hasSuccess);
          setRouter("home");
        }}
      />
    );
  }

  const animationClass = hasAnimated ? "" : "animate";
  const animationFasterClass = hasAnimated ? "" : "animate-faster";

  return (
    <div className="flex flex-col items-center md:justify-center min-h-screen bg-gray-50 text-slate-800 dark:bg-slate-800 dark:text-zinc-100 p-10 lg:p-40">
      <div className="flex flex-col space-y-6 text-3xl text-center font-bold tracking-tighter cursor-default max-w-7xl">
        <div
          className={`bg-gray-50 dark:bg-slate-800 ${animationClass} fadeInDown`}
        >
          <span className="text-6xl font-bold tracking-tighter">
            {t("companyName")}
          </span>
          <span className="text-xl opacity-80 block -mt-1">
            {t("commpanyNameSuffix")}
          </span>
        </div>
        <div
          className={`${animationClass} animate-delay-25 fadeInUp`}
          dangerouslySetInnerHTML={{ __html: mainText }}
        ></div>
        <div
          className={`flex flex-col md:flex-row justify-center items-center tracking-normal ${animationClass} fadeInUp animate-delay-75`}
        >
          <div className="apps-title border-l-0 border-b-4 md:border-b-0 md:border-l-4 border-purple-600 pl-0 md:pl-1 pb-1 md:pb-0 mr-0 md:mr-6 mb-6 md:mb-0 min-w-28 md:min-w-0">
            {t("appsTitle")}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {apps.map((app, index) => (
              <div
                key={index}
                className="flex flex-row items-center space-x-1 cursor-pointer hover:underline"
                onClick={() => {
                  window.location.href = urlForApp(app.app_id);
                }}
              >
                <img src={`/${app.icon}`} alt={app.title} className="w-8 h-8" />
                <div className="text-xl font-semibold">{app.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        id="contact-shortcut"
        className={`fixed ${animationFasterClass} fadeInRight bg-slate-800 dark:bg-white text-white dark:text-slate-800 hover:bg-blue-600 dark:hover:text-white w-auto p-4 rounded-full cursor-pointer shadow-2xl animate-delay-150`}
        onClick={() => {
          setRouter("contact");
        }}
      >
        <HiMiniChatBubbleLeftRight className="text-3xl" />
      </div>
    </div>
  );
}

export default App;
