import React, { useState, Fragment, useEffect } from "react";
import { RiLoader3Fill } from "react-icons/ri";
import { FaPaperPlane } from "react-icons/fa6";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase.config";

function Contact({ t, hasSuccess, onClose }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formState, setFormState] = useState(""); // default state, loading, success, error

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormState("loading");
    try {
      await addDoc(collection(db, "contact_messages"), {
        name: name,
        phone: phone,
        email: email,
        message: message,
        timestamp: serverTimestamp(),
      });
      setFormState("success");
    } catch (error) {
      setFormState("error");
    }
  };

  useEffect(() => {
    if (hasSuccess) {
      setFormState("success");
    }
  }, [hasSuccess]);

  return (
    <div
      className="flex flex-col items-center md:justify-center min-h-screen min-w-screen tracking-tighter px-4"
      id="contact-wrapper"
    >
      <div className="bg-white text-slate-900 shadow-lg rounded-lg px-6 py-6 mx-4 my-8 flex flex-col w-full max-w-screen-md animate-faster fadeIn">
        {formState === "success" && (
          <Fragment>
            <div className="flex flex-col cursor-default text-green-600 items-center justify-center text-center">
              <div className="mb-4">
                <FaPaperPlane className="text-3xl animate-faster fadeInDown" />
              </div>
              <div className="text-2xl font-semibold animate-faster fadeInUp animate-delay-25">
                {t("contactFormSuccess")}
              </div>
              <div className="animate-faster fadeInUp animate-delay-50">
                {t("contactFormSuccessDetail")}
              </div>
              <div
                className="bg-zinc-200 hover:bg-zinc-300 text-zinc-500 font-bold py-2 px-6 rounded-full cursor-pointer w-full md:w-auto mt-4"
                onClick={() => {
                  onClose(true);
                }}
              >
                {t("close")}
              </div>
            </div>
          </Fragment>
        )}
        {formState !== "success" && (
          <Fragment>
            <div className="flex flex-col cursor-default">
              <div className="text-2xl font-semibold">{t("contactTitle")}</div>
              <div className="mb-4 pb-4 border-b border-gray-200">
                {t("contactDescription")}
              </div>
            </div>

            <form className="w-full" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3 mb-3">
                {/* Name input */}
                <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact-form-name"
                  >
                    {t("contactFormName")}
                  </label>
                  <input
                    required
                    disabled={formState === "loading"}
                    id="contact-form-name"
                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                {/* Phone input */}
                <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact-form-phone"
                  >
                    {t("contactFormPhone")}
                  </label>
                  <input
                    id="contact-form-phone"
                    disabled={formState === "loading"}
                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3">
                {/* Email input */}
                <div className="w-full px-3 mb-3 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact-form-email"
                  >
                    {t("contactFormEmail")}
                  </label>
                  <input
                    required
                    disabled={formState === "loading"}
                    id="contact-form-email"
                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {/* Message input */}
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact-form-message"
                  >
                    {t("contactFormMessage")}
                  </label>
                  <textarea
                    id="contact-form-message"
                    disabled={formState === "loading"}
                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    required
                    minLength="20"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center min-h-10 text-center">
                {formState === "loading" && (
                  <div className="flex w-full justify-center items-center text-slate-800 text-2xl cursor-default">
                    <div className="loader">
                      <RiLoader3Fill />
                    </div>
                  </div>
                )}

                {formState !== "loading" && formState !== "success" && (
                  <div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                    <button
                      type="submit"
                      className="bg-slate-800 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full w-full"
                    >
                      {t("contactFormSubmit")}
                    </button>
                    <div
                      className="bg-zinc-200 hover:bg-zinc-300 text-zinc-500 font-bold py-2 px-6 rounded-full cursor-pointer w-full md:w-auto"
                      onClick={() => {
                        onClose(false);
                      }}
                    >
                      {t("close")}
                    </div>
                  </div>
                )}

                {
                  // Show error message
                  formState === "error" && (
                    <div className="text-red-500 cursor-default mt-4">
                      {t("contactFormError")}
                    </div>
                  )
                }
              </div>
            </form>
          </Fragment>
        )}
      </div>
      <div className="flex flex-col w-full max-w-screen-md animate-faster fadeIn text-xs cursor-default pb-8">
        <p className="font-bold opacity-50">{t("imprint")}.</p>
        <p className="opacity-50">
          Ref Yazılım ve Bilişim Teknolojileri Tic. Ltd. Şti.
        </p>
        <p className="opacity-50">
          Barbaros Mah. Begonya Sk. Nidakule Ataşehir Batı Blok No: 1 İç Kapı
          No: 2 Ataşehir / İstanbul
        </p>
      </div>
    </div>
  );
}

export default Contact;
